import { ChakraProvider } from '@chakra-ui/react';
import { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';

import { appTheme } from '../styles/theme';
import { Routes } from '../lib/routes';

export const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps: { ...pageProps } }: AppProps) => {
	const { replace } = useRouter();

	useEffect(() => {
		queryClient.getQueryCache().config.onError = (e) => {
			replace(Routes.login());
		};
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<ChakraProvider theme={appTheme}>
				<Analytics />
				<Component {...pageProps} />
			</ChakraProvider>
		</QueryClientProvider>
	);
};

export default MyApp;
